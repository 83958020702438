import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Images } from "../assets/images/Images";

const Navbar = () => {
	return (
		<div>
			<div className="nav4landing">
				{/* <nav className="container"> */}
				<div className="navbar ">
					<div className="center">
						<Image
							unoptimized
							src={Images.logo}
							width="190"
							height="27"
							alt="instincthub logo"
						/>
					</div>
				</div>
				{/* </nav> */}
			</div>
		</div>
	);
};
export default Navbar;
